<template>
	<div class="home-content">
		<div v-if="innerWidth>1200">
			<div class="carousel-map">
				<!-- <el-carousel ref="carousel" :interval="5000" :initial-index="initialIndex" indicator-position="none" arrow="never"
					@change="changeImg" trigger="click" height="844px">
					<el-carousel-item v-for="(item, index) in bannerList" :key="index"> -->
				<div class="el-carousel__item">
					<img :class="bannerClassName" class="banner-img" :src="getImgUrl + item.image" fit="cover"
						v-for="(item,index) in bannerList" :key="index" v-show="initialIndex == index" />
					<div class="banner-text animate__animated animate__fadeInUp">
						<div class="banner-text-name">{{$t('lang.rebaNew')}}</div>
						<div class="banner-text-content">{{ imgText }}</div>
						<div class="banner-text-btn" @click="bannerDetails(bannerList[initialIndex])">
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/banner-btn.png" alt="" />
							<p>{{$t('lang.understand')}}</p>
						</div>
					</div>
				</div>
				<!-- </el-carousel-item>
				</el-carousel> -->
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/prev-img.png" alt="" class="prev-img"
					@click="setPrev" />
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/next-img.png" alt="" class="next-img"
					@click="setNext" />
				<div class="carousel-map-lns">
					<!-- style="transform: matrix(1, 0, 0, 1, 256, 0);" -->
					<!-- <div class="carousel-map-lns-left"></div> -->
					<div class="carousel-map-lns-wrapper">
						<div class="carousel-map-lns-block" v-for="item, index in bannerList" :key="index">
							<div class="dot-line"></div>
							<div class="first" v-if="initialIndex == index">
								<span class="text">{{ item.title }}</span>
								<svg data-v-9fed2d6e="" class="first-size" viewBox="0 0 100 40" version="1.1"
									xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<rect data-v-9fed2d6e="" stroke-width="1" stroke="rgba(255, 255, 255, 0.5)"
										fill="none" x="0.5" y="0.5" width="99" height="39" rx="19.5"></rect>
									<rect data-v-9fed2d6e="" stroke-width="1" stroke="rgba(255, 255, 255, 1)"
										fill="none" x="0.5" y="0.5" width="99" height="39" rx="19.5"
										class="rect-highlight" :style="{
											'stroke-dasharray': 241.733, 'stroke-dashoffset': strokeDashoffset + 'px'
										}">
									</rect>
								</svg>
							</div>
							<div class="dot-dot" v-else></div>
						</div>
					</div>
					<div class="carousel-map-lns-right"></div>
				</div>
			</div>
			<div class="intro">
				<div class="intro-text">
					<div class="intro-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">{{$t('lang.rebaNew')}}</div>
					<div class="intro-center wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.intro')}}
					</div>
					<div class="intro-content wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.intro1')}}
					</div>
					<div class="intro-num">
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num1 }}
								<div class="add">+</div>
							</div>
							<div class="intro-num-label">
								{{$t('lang.tireCustomer')}}
							</div>
						</div>
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num2 }}
								<div class="add">+</div>
							</div>
							<div class="intro-num-label">
								{{$t('lang.rubberPlastic')}}
							</div>
						</div>
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num3 }}
								<div class="add">+</div>
							</div>
							<div class="intro-num-label">
								{{$t('lang.shoeMaterial')}}
							</div>
						</div>
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num4 }}
							</div>
							<div class="intro-num-label">
								{{$t('lang.establishmentTime')}}
							</div>
						</div>
					</div>

					<div class="intro-btn" style="margin-left: 144px;" @click="goIntroduce">
						<p>{{$t('lang.seeMore')}}</p>
					</div>
				</div>
				<img :src="videoImg" alt="" class="video-img wow animate__animated animate__fadeInUp"
					data-wow-delay="0.5s" data-wow-duration="2s" />
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/intro-video-bg.png" alt=""
					class="intro-video-bg wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s" />
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/pause-img.png"
					@click="dialogTableVisible = true" alt="" class="pause-img wow animate__animated animate__fadeInUp"
					data-wow-delay="0.5s" data-wow-duration="2s" />
			</div>
			<div class="solution" :style="{'backgroundImage':'url('+optionImg+')','backgroundSize':'100% 100%'}">
				<div class="solution-nav wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					<div class="solution-nav-list">
						<div class="solution-nav-item" :class="index == solutionNavIndex ? 'solution-nav-items' : ''"
							v-for="(item, index) in optionList" :key="index" @mouseenter="setNavIndex(item,index)">
							<div>{{item.name}}</div>
						</div>
					</div>
				</div>
				<div class="solution-content">
					<!-- <div class="solution-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.tabSolution')}}
					</div>
					<div class="solution-intro wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.tabSolutions')}}
					</div> -->
					<div class="solution-text">
						<div class="solution-text-name wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">{{optionItem.name}}</div>
						<p class="wow animate__animated animate__fadeInUp" data-wow-delay="0.5s" data-wow-duration="2s"
							v-html="optionItem.description"></p>
						<!-- <p>硫化树脂:SP1045 SP1055 LS2045 LS2055</p>
						<p>交联助剂:HVA-2</p>
						<p>流动加工助剂: PW AC617</p>
						<p>内脱模剂:IM-1 RL-16</p>
						<p>防老剂: 1076</p>
						<p>着色剂:环保氧化铁红</p>
						<p>氧化镁:RS150(活性)RS02(轻质)</p>
						<p>分散剂:RF-50 RF-72</p>
						<p>预分散促进剂:TMTD-75GE DTDM-80GE ZDMC-75GE DPTT-75GE</p>
						<p>预分散硫化剂:HDS-80GE IS-75GE S-80GE</p> -->
					</div>
				</div>
			</div>
			<div class="product">
				<div class="product-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					{{$t('lang.reba')}}
				</div>
				<div class="product-block">
					<div class="product-block-left">
						<div class="product-block-left-line wow animate__animated animate__fadeInUp"
							data-wow-delay="0.5s" data-wow-duration="2s"></div>
						<div class="product-block-left-menu wow animate__animated animate__fadeInUp"
							data-wow-delay="0.5s" data-wow-duration="2s">
							<div :class="productType == 1?'active pointer':'pointer'" @click="setProduct(1)">
								{{$t('lang.tabRubberAdditive')}}
							</div>
							<div :class="productType == 2?'pointer active':'pointer'" @click="setProduct(2)">
								{{$t('lang.tabElectronicChemicals')}}
							</div>
						</div>
						<div class="intro-btn wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s" style="margin-top: 170px" @click="goProduct">
							<p>{{$t('lang.seeMore')}}</p>
						</div>
					</div>
					<div class="product-block-right wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						<div class="product-block-right-list">
							<div class="product-block-right-item" v-for="item, index in productList" :key="index"
								@click="goProduct(item)" v-if="index<3">
								<img class="coverImg" :src="getImgUrl + item.image" alt="" srcset="">
								<div class="swiper-slide-bottom">
									<div class="swiper-slide-bottom-left singe-line">
										{{ item.name }}
									</div>
									<img class="iconImg"
										src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/product-go.png"
										alt="" srcset="" />
								</div>
							</div>
							<!-- <div class="swiper-slide" style="background: #F3F9F7;"></div> -->
						</div>
					</div>
					<!-- <div class="product-block-right wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						<div class="swiper-container" :style="{width:((productList.length-1)*56)+'px'}">
							<div class="swiper-wrapper">
								<div class="swiper-slide" style="width: 380px;border-radius: 8px;"
									v-for="item, index in productList" :key="index" @click="goProduct(item)">
									<img class="coverImg" :src="getImgUrl + item.image" alt="" srcset="">
									<div class="swiper-slide-bottom">
										<div class="swiper-slide-bottom-left singe-line">
											{{ item.name }}
										</div>
										<img class="iconImg" src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/product-go.png" alt="" srcset="" />
									</div>
								</div>
								<div class="swiper-slide" style="background: #F3F9F7;"></div>
							</div>
							如果需要分页器
							<div class="swiper-pagination"></div>

							如果需要滚动条
							<div class="swiper-scrollbar"></div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="news">
				<div class="news-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					{{$t('lang.newsTrends')}}
				</div>
				<div class="news-block wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					<div class="swiper-container1">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="item, index in newsList" :key="index">
								<div class="swiper-slide-child">
									<div class="swiper-slide-block" v-for="child, cIndex in item" :key="cIndex"
										@click="goNewsDetails(child)">
										<img class="s-img" :src="getImgUrl + child.image" alt="" srcset="">
										<div class="s-name">
											{{ child.name }}
										</div>
										<div class="s-active">{{$t('lang.viewNow')}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div>
					</div>
				</div>
			</div>
			<el-dialog :visible.sync="dialogTableVisible">
				<template #header v-if="false"></template>
				<!-- <video :src="videoUrl" style="width: 100%;"></video> -->
				<video v-if="videoUrl" :show-fullscreen-btn="true" :src="videoUrl" style="width: 100%;"
					controls="controls"></video>
				<template #footer v-if="false"></template>
			</el-dialog>
		</div>

		<div v-if="innerWidth <= 1200">
			<div class="carousel">
				<el-carousel :interval="5200" ref="carousel_banner" height="95vh">
					<el-carousel-item v-for="item,index in bannerList" :key="index">
						<div class="carousel-center">
							<img :src="getImgUrl + item.image_m" alt="" />
							<div class="carousel-center-text">
								<div class="carousel-title">
									{{item.desc}}
								</div>
								<div class="carousel-name">
									{{item.title}}
								</div>
								<div class="carousel-btn" @click="bannerDetails(item)">
									<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/carousel-btn.png"
										alt="" />
									<span>{{$t('lang.furtherUnderstanding')}}</span>
								</div>
							</div>
							<div class="first">
								<div class="first-center">
									<span class="text">{{$t('lang.tabSolution')}}</span>
									<svg data-v-9fed2d6e="" class="first-size" viewBox="0 0 100 40" version="1.1"
										xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
										<rect data-v-9fed2d6e="" stroke-width="1" stroke="rgba(255, 255, 255, 0.5)"
											fill="none" x="0.5" y="0.5" width="99" height="39" rx="19.5"></rect>
										<rect data-v-9fed2d6e="" stroke-width="1" stroke="rgba(255, 255, 255, 1)"
											fill="none" x="0.5" y="0.5" width="99" height="39" rx="19.5"
											class="rect-highlight" :style="{
												'stroke-dasharray': 241.733, 'stroke-dashoffset': strokeDashoffset + 'px'
											}">
										</rect>
									</svg>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="ruiba">
				<div class="ruiba-img">
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/pay.png" alt="" class="pay"
						@click="dialogTableVisible = true" />
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/right-bg.png" alt=""
						class="right-bg" />
					<img :src="videoImg" alt="" class="video-img" />
				</div>
				<div class="ruiba-center">
					<div class="name wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.rebaNew')}}
					</div>
					<div class="intro wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.intro')}}
					</div>
					<div class="text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.intro1')}}。
					</div>
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/home-bg.png" alt=""
						class="home-bg" />
					<div class="intro-num">
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num1 }}
								<div class="add">+</div>
							</div>
							<div class="intro-num-label">
								{{$t('lang.tireCustomer')}}
							</div>
						</div>
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num2 }}
								<div class="add">+</div>
							</div>
							<div class="intro-num-label">
								{{$t('lang.rubberPlastic')}}
							</div>
						</div>
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num3 }}
								<div class="add">+</div>
							</div>
							<div class="intro-num-label">
								{{$t('lang.shoeMaterial')}}
							</div>
						</div>
						<div class="intro-num-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div class="intro-num-value">
								{{ num4 }}
							</div>
							<div class="intro-num-label">
								{{$t('lang.establishmentTime')}}
							</div>
						</div>
					</div>
					<div class="intro-btn wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						<p>{{$t('lang.seeMore')}}</p>
					</div>
				</div>
			</div>
			<div class="option">
				<div class="option-center"
					:style="{'backgroundImage':'url('+optionImg_m+')','backgroundSize':'100% 100%'}">
					<!-- <div class="option-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.tabSolution')}}
					</div>
					<div class="option-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{$t('lang.tabSolutions')}}
					</div> -->
					<div class="tabs">
						<div class="list wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<div :class="index==solutionNavIndex?'items':'item'" v-for="(item,index) in optionList"
								:key="index" @click="setNavIndex(item,index)">
								{{item.name}}
							</div>
						</div>
						<div class="select wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s" @click="tabsShow = !tabsShow">
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/select-b.png" alt="" />
						</div>
						<div class="tabs-card" v-if="tabsShow">
							<div class="card-list">
								<div class="card-item" v-for="(item,index) in optionList" :key="index"
									@click="setNavIndex(item,index)">
									<span>{{item.name}}</span>
									<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png"
										alt="" />
								</div>
							</div>
						</div>
					</div>
					<div class="item-center">
						<div class="item-name wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							{{optionItem.name}}
						</div>
						<div class="item-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
							data-wow-duration="2s">
							<p v-html="optionItem.description"></p>
							<!-- <p>硫化树脂:SP1045 SP1055 LS2045 LS2055</p>
							<p>交联助剂:HVA-2</p>
							<p>流动加工助剂: PW AC617</p>
							<p>内脱模剂:IM-1 RL-16</p>
							<p>防老剂: 1076</p>
							<p>着色剂:环保氧化铁红</p>
							<p>氧化镁:RS150(活性)RS02(轻质)</p>
							<p>分散剂:RF-50 RF-72</p>
							<p>预分散促进剂:TMTD-75GE DTDM-80GE ZDMC-75GE DPTT-75GE</p>
							<p>预分散硫化剂:HDS-80GE IS-75GE S-80GE</p> -->
						</div>
					</div>
				</div>
			</div>
			<div class="product">
				<div class="product-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					{{$t('lang.reba')}}
				</div>
				<div class="line wow animate__animated animate__fadeInUp" data-wow-delay="0.5s" data-wow-duration="2s">
				</div>
				<div :class="productType == 1?'status1 wow animate__animated animate__fadeInUp':'status2 wow animate__animated animate__fadeInUp'"
					data-wow-delay="0.5s" data-wow-duration="2s" @click="setProduct(1)">
					{{$t('lang.tabRubberAdditive')}}
				</div>
				<div :class="productType == 2?'status1 wow animate__animated animate__fadeInUp':'status2 wow animate__animated animate__fadeInUp'"
					data-wow-delay="0.5s" data-wow-duration="2s" @click="setProduct(2)">
					{{$t('lang.tabElectronicChemicals')}}
				</div>
				<div class="product-list wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					<div class="product-item" v-for="(item,index) in productList" :key="index" @click="goProduct(item)">
						<img :src="getImgUrl + item.image" alt="" />
						<div class="item-text">
							<div class="name">
								{{item.name}}
							</div>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/arrow.png" alt="" />
						</div>
					</div>
				</div>
				<div class="intro-btn wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					<p>{{$t('lang.seeMore')}}</p>
				</div>
			</div>
			<div class="news-center">
				<div class="news-title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					{{$t('lang.newsTrends')}}
				</div>
				<div class="news-carousel wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s">
					<el-carousel ref="news_carousel" :interval="5000" height="344px" arrow="always"
						@change="carouselChange">
						<el-carousel-item v-for="(item,index) in newsDataList" :key="index">
							<div class="item-text">
								<img :src="getImgUrl + item.image" alt="" />
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="news-text">
					<div class="news-text-name wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s">
						{{newsItem.name}}
					</div>
					<div class="news-text-btn wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
						data-wow-duration="2s" @click="goNewsDetails(newsItem)">
						{{$t('lang.viewNow')}}
					</div>
				</div>
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/group-left.png" alt=""
					class="group-left wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s" @click="setNewsPrev" />
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/group-right.png" alt=""
					class="group-right wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"
					data-wow-duration="2s" @click="setNewsNext" />
			</div>
			<el-dialog width="90%" :show-close="false" :visible.sync="dialogTableVisible">
				<template #header v-if="false"></template>
				<!-- <video :src="videoUrl" style="width: 100%;"></video> -->
				<video v-if="videoUrl" :show-fullscreen-btn="true" :src="videoUrl" style="width: 100%;"
					controls="controls"></video>
				<template #footer v-if="false"></template>
			</el-dialog>
		</div>

	</div>
</template>
<script>
	import Swiper from "swiper";
	import "../../node_modules/swiper/dist/css/swiper.min.css";
	import "../../node_modules/swiper/dist/js/swiper.min.js";
	export default {
		name: "home",
		data() {
			return {
				bannerList: [], //轮播图地址 这块写你们的轮播图数组即可
				videoUrl: '',
				videoImg: '',
				bannerIndex: 0,
				bannerClassName: "", //轮播图名字
				one: true,
				num1: 70,
				num2: 5000,
				num3: 200,
				num4: 2000,
				solutionNavIndex: 0,
				initialIndex: 0,
				strokeDashoffset: 242,
				jsSetIntervalTime: null,
				imgUrl: '',
				imgText: "",
				dialogTableVisible: false,
				scrollbarDraggable: true,
				productType: 1,
				productList: [],
				newsList: [],
				pageYOffset: 0,
				innerWidth: 1200,
				tabsShow: false,
				scrollShow: true,
				optionList: [],
				optionItem: '',
				newsIndex: 0,
				newsItem: {},
				newsDataList: [],
				optionImg: '',
				optionImg_m: '',
			};
		},
		watch: {
			'$i18n.locale'(val) {
				this.getProductA();
				this.getNewsList();
				this.getBanner();
			}
		},
		mounted() {
			let that = this;
			that.$nextTick(() => {
				that.$wow.init()
			})
			that.innerWidth = window.innerWidth;
			window.onresize = () => {
				return (() => {
					that.innerWidth = window.innerWidth;
					if (that.innerWidth > 1200) {
						window.addEventListener("scroll", that.handleScroll);
						import("@/styles/home-pc.scss");
					} else {
						window.removeEventListener('scroll', that.handleScroll)
						import("@/styles/home-h5.scss");
					}
				})()
			}
			that.slideBanner();
			that.getBanner();
			that.getNewsList();
			that.getProductA();

			if (that.innerWidth > 1200) {
				window.addEventListener("scroll", that.handleScroll);
				import("@/styles/home-pc.scss");
			} else {
				window.removeEventListener('scroll', that.handleScroll)
				import("@/styles/home-h5.scss");
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
		methods: {
			goIntroduce() {
				this.$router.push({
					path: '/introduce',
				});
			},
			goNewsDetails(item) {
				this.$router.push({
					path: '/news-details',
					query: {
						id: item.id,
						type: 1,
					}
				});
			},
			bannerDetails(item) {
				window.location.href = item.url;
			},
			goProduct(item) {
				if (this.innerWidth > 1200) {
					if (this.productType == 1) {
						console.log('item', item)
						this.$router.push({
							path: '/rubber-additive',
							query: {
								// type: item.pcategory_id == 14 ? 1 : 2,
								type: 1,
								productId: item.category_id,
								itemId: item.id,
							}
						})
					} else {
						this.$router.push({
							path: '/electronic-chemicals',
							query: {
								productId: item.category_id,
								itemId: item.id,
							}
						})
					}
				} else {
					this.$router.push({
						path: '/product-details',
						query: {
							id: item.id,
							type: item.pcategory_id == 14 ? 1 : item.pcategory_id == 15 ? 2 : 3,
						}
					})
				}
			},
			setProduct(e) {
				this.productType = e;
				this.getProductA();
			},
			getProductA() {
				this.http.post('/index.php/api/index/productB', {
					type: this.productType == 1 ? 'rubber' : 'chemical'
				}).then(res => {
					if (res.code == 1) {
						this.productList = res.data;
						this.$nextTick(() => {
							this.getProductBanner();
						})
					}
				})
			},
			getNewsList() {
				this.http.post('/index.php/api/index/news').then(res => {
					if (res.code == 1) {
						this.newsDataList = res.data.data;
						this.newsList = this.getNewArr(res.data.data, 2);
						this.newsItem = res.data.data[this.newsIndex];

						this.$nextTick(() => {
							this.getNewsBanner();
						})
					}
				})
			},
			getNewArr(arr, n) {
				let newArr = [];
				for (let i = 0; i < arr.length; i += n) {
					newArr.push(arr.slice(i, i + n))
				}
				return newArr;
			},
			carouselChange(e) {
				this.newsIndex = e;
				this.newsItem = this.newsDataList[this.newsIndex];
			},
			// goDetails(item) {
			// 	this.$router.push({
			// 		path: '/news-details',
			// 		query: {
			// 			id: item.id
			// 		}
			// 	})
			// },
			getBanner() {
				this.http.post('/index.php/api/index/index').then(res => {
					if (res.code == 1) {
						this.bannerList = res.data.ads;
						this.imgUrl = res.data.ads[0].image;
						this.imgText = res.data.ads[0].desc;
						this.videoUrl = this.getImgUrl + res.data.video;
						this.videoImg = this.getImgUrl + res.data.vimage;
						this.optionList = res.data.fanan;
						this.optionItem = res.data.fanan[0];
						this.optionImg = this.getImgUrl + res.data.fanan[0].image;
						this.optionImg_m = this.getImgUrl + res.data.fanan[0].image_m;
						this.changeImg(0);
					}
				})
			},
			handleScroll() {
				// 页面滚动距顶部距离
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop < 300) {
					this.$nextTick(() => {
						this.$wow.init()
					})
				}
				let scroll = scrollTop - this.scroll_i;
				this.scroll_i = scrollTop;
				if (scroll < 0) {
					if (scrollTop < 300) {
						this.scrollShow = true;
					}
					if (this.scrollShow && scrollTop == 600) {
						this.scrollShow = false;
						this.digitalScroll();
					}
				} else {
					if (scrollTop > 700) {
						if (this.scrollShow) {
							this.scrollShow = false;
							this.digitalScroll();
						}
					}
				}
				this.pageYOffset = window.pageYOffset
			},
			// 产品
			getProductBanner() {
				new Swiper('.swiper-container', {
					loop: false,
					autoplay: false,
					slidesPerView: 3,
					spaceBetween: 58,
					scrollbar: '.swiper-scrollbar',
					scrollbarHide: false,
					scrollbarDraggable: true,
					scrollbarSnapOnRelease: true,
				})
			},
			// 新闻
			getNewsBanner() {
				new Swiper('.swiper-container1', {
					loop: true,
					loopedSlides: 3,
					spaceBetween: 50,
					centeredSlides: true,
					slidesPerView: 'auto',
					nextButton: '.swiper-button-next',
					prevButton: '.swiper-button-prev',
				})
			},
			setNavIndex(item, index) {
				this.solutionNavIndex = index;
				this.optionItem = item;
				this.optionImg = this.getImgUrl + item.image;
				this.optionImg_m = this.getImgUrl + item.image_m;
				this.tabsShow = false;
			},
			//上一张
			setPrev() {
				if (this.initialIndex > 0) {
					--this.initialIndex
				} else {
					this.initialIndex = this.bannerList.length - 1
				}
				this.changeImg(this.initialIndex)
				// this.$refs.carousel.prev();
			},
			setNext() {
				let len = this.bannerList.length
				if (this.initialIndex < len - 1) {
					++this.initialIndex
				} else {
					this.initialIndex = 0
				}
				this.changeImg(this.initialIndex)
				// this.$refs.carousel.next();
			},
			setNewsPrev() {
				this.$refs.news_carousel.prev();
			},
			setNewsNext() {
				this.$refs.news_carousel.next();
			},
			//轮播图切换
			changeImg(e) {
				this.initialIndex = e;
				this.imgUrl = this.bannerList[this.initialIndex].image;
				this.imgText = this.bannerList[this.initialIndex].desc;
				this.bannerClassName = "lun-img";
				setTimeout(() => {
					this.bannerClassName = "lun-img-two";
				}, 300);
				clearInterval(this.jsSetIntervalTime)
				this.jsSetIntervalTime = null
				this.strokeDashoffset = 242
				this.jsSetInterval()
			},
			jsSetInterval() {
				this.jsSetIntervalTime = setInterval(() => {
					--this.strokeDashoffset
					if (this.strokeDashoffset == 0) {
						clearInterval(this.jsSetIntervalTime)
						this.jsSetIntervalTime = null
						let len = this.bannerList.length
						setTimeout(() => {
							this.strokeDashoffset = 242
							if (this.initialIndex < len) {
								this.setNext()
							} else {
								this.setPrev()
							}
						}, 500)
					}
				}, 20)
			},
			digitalScroll() {
				let time1 = "";
				clearInterval(time1);
				this.num1 = 0;
				time1 = setInterval(() => {
					if (this.num1 < 70) {
						this.num1 += 5;
					}
					if (this.num1 == 70) {
						clearInterval(time1);
						this.num1 = 70;
					}
				}, 100);

				let time2 = "";
				clearInterval(time2);
				this.num2 = 0;
				time2 = setInterval(() => {
					if (this.num2 < 5000) {
						this.num2 += 200;
					}
					if (this.num2 == 5000) {
						clearInterval(time2);
						this.num2 = 5000;
					}
				}, 100);

				let time3 = "";
				clearInterval(time3);
				this.num3 = 0;
				time3 = setInterval(() => {
					if (this.num3 < 200) {
						this.num3 += 10;
					}
					if (this.num3 == 200) {
						clearInterval(time3);
						this.num3 = 200;
					}
				}, 100);

				let time4 = "";
				clearInterval(time4);
				this.num4 = 0;
				time4 = setInterval(() => {
					if (this.num4 < 2000) {
						this.num4 += 100;
					}
					if (this.num4 == 2000) {
						clearInterval(time4);
						this.num4 = 2000;
					}
				}, 100);
			},
			// banner滑动切换
			slideBanner() {
				//选中的轮播图
				var box = document.querySelector('.el-carousel__container');
				var startPointX = 0
				var stopPointX = 0
				//重置坐标
				var resetPoint = function() {
					startPointX = 0
					stopPointX = 0
				}
				//手指按下
				box.addEventListener("touchstart", function(e) {
					//手指点击位置的X坐标
					startPointX = e.changedTouches[0].pageX
				});
				//手指滑动
				box.addEventListener("touchmove", function(e) {
					//手指滑动后终点位置X的坐标
					stopPointX = e.changedTouches[0].pageX
				});
				//当手指抬起的时候，判断图片滚动离左右的距离
				let that = this
				box.addEventListener("touchend", function(e) {
					if (stopPointX == 0 || startPointX - stopPointX == 0) {
						resetPoint()
						return
					}
					if (startPointX - stopPointX > 0) {
						resetPoint()
						that.$refs.carousel_banner.next()
						clearInterval(that.jsSetIntervalTime)
						that.jsSetIntervalTime = null
						that.strokeDashoffset = 242
						that.jsSetInterval()
						return
					}
					if (startPointX - stopPointX < 0) {
						resetPoint()
						that.$refs.carousel_banner.prev()
						clearInterval(that.jsSetIntervalTime)
						that.jsSetIntervalTime = null
						that.strokeDashoffset = 242
						that.jsSetInterval()
						return
					}
				});
			},
		},
	};
</script>
<style scoped lang="scss">
	/* @import url("../styles/home-pc.scss") screen and (min-width: 1200px);
	@import url("../styles/home-h5.scss") screen and (max-width: 1200px); */
</style>