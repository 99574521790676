import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about-us',
		name: 'about-us',
		component: () => import('../views/about-us.vue'),
		children: [{
			path: '/introduce',
			name: 'introduce',
			component: () => import('../views/about-us/introduce.vue'),
			meta: {
				title: '公司介绍',
				tabIndex: 0,
			}
		},
		{
			path: '/culture',
			name: 'culture',
			component: () => import('../views/about-us/culture.vue'),
			meta: {
				title: '企业文化',
				tabIndex: 1,
			}
		},
		{
			path: '/honor',
			name: 'honor',
			component: () => import('../views/about-us/honor.vue'),
			meta: {
				title: '公司荣誉',
				tabIndex: 2,
			}
		},
		{
			path: '/news',
			name: 'news',
			component: () => import('../views/about-us/news.vue'),
			meta: {
				title: '新闻中心',
				tabIndex: 3,
			}
		},
		{
			path: '/innovate',
			name: 'innovate',
			component: () => import('../views/about-us/innovate.vue'),
			meta: {
				title: '科研创新',
				tabIndex: 4,
			}
		},
		{
			path: '/develop',
			name: 'develop',
			component: () => import('../views/about-us/develop.vue'),
			meta: {
				title: '可持续发展',
				tabIndex: 5,
			}
		}]
		
	},
	{
		path: '/news-details',
		name: 'news-details',
		component: () => import('../views/news-details.vue')
	},
	{
		path: '/rubber-additive',
		name: 'rubber-additive',
		component: () => import('../views/rubber-additive.vue')
	},
	{
		path: '/product-details',
		name: 'product-details',
		component: () => import('../views/product-details.vue')
	},
	{
		path: '/electronic-chemicals',
		name: 'electronic-chemicals',
		component: () => import('../views/electronic-chemicals.vue')
	},
	{
		path: '/recruitment-information',
		name: 'recruitment-information',
		component: () => import('../views/recruitment-information.vue')
	},
	{
		path: '/solution',
		name: 'solution',
		component: () => import('../views/solution.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product.vue')
	},
	{
		path: '/contact-us',
		name: 'contact-us',
		component: () => import('../views/contact-us.vue')
	},
]

const router = new VueRouter({
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			// vue2.0  x  y  控制
			// vue3.0  left  top 控制
			if(to.matched[0].path != '/about-us'){
				return {
					x: 0,
					y: 0
				}
			}
		}
	}
})

export default router