<template>
	<div id="app">
		<navigationBarPc></navigationBarPc>
		<router-view />
		<bottomInformation></bottomInformation>
		<img src="./assets/home/upper-icon.png" alt="" class="upper-icon" @click="goUpper()" v-if="btnFlag" />
	</div>
</template>

<script>
	import navigationBarPc from './components/navigation-bar.vue'
	import bottomInformation from './components/bottom-information.vue'
	export default {
		components: {
			navigationBarPc,
			bottomInformation
		},
		data() {
			return {
				btnFlag: false,
			}
		},
		mounted() {
			let that = this
			window.addEventListener('scroll', that.scrollToTop, true);
		},
		destroyed: function() {
			window.removeEventListener('scroll', this.scrollToTop)
		},
		methods: {
			goUpper() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				}); // 平滑滚动到顶部
			},
			// 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			scrollToTop() {
				const that = this
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop ||
					document.querySelector('#app').scrollTop
				if (scrollTop > 800) {
					that.btnFlag = true
				} else {
					that.btnFlag = false
				}
			}
		}
	}
</script>

<style lang="scss">
	body,
	html,
	p,
	ul,
	li,
	div {
		margin: 0px;
		padding: 0px;
		height: auto;
		font-family: SourceHanSansCN-Regular;
	}

	html {
		width: 1920px;
		height: auto;
		overflow-x: hidden;
	}
	
	body {
	  padding-right: 0 !important;
	}

	.upper-icon {
		width: 120px;
		// height: 42px;
		position: fixed;
		bottom: 35px;
		right: 120px;
		z-index: 99999999;
		cursor: pointer;
	}

	.pointer {
		cursor: pointer;
	}

	.singe-line {
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		white-space: nowrap;
	}

	.double-line {
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.three-line {
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	//el-popover
	.el-popover {
		width: 1920px;
		height: 62px;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none !important;
		box-shadow: none !important;
		margin-left: -5px;
		margin-top: -1px !important;
		z-index: 1000 !important;
		border-top: 1px solid #EFEFEF !important;

		.tab-item {
			margin: 0 64px;
			height: 62px;
			line-height: 62px;
			font-size: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			color: #666666;
		}
	}

	.el-input__inner {
		border: none !important;
		cursor: pointer;
	}
</style>