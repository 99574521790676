<template>
	<div class="bottom-information-content">
		<div class="center" v-if="innerWidth>1200">
			<div class="center-top">
				<div class="center-left">
					<div class="center-left-icon">
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/icon1.png" alt="" />
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/icon2.png" alt="" />
					</div>
					<div class="connection-name">
						{{$t('lang.botText')}}
					</div>
					<div class="connection-img">
						<div>
							<img :src="list.qrcode1" alt="" />
							<p>{{$t('lang.botText1')}}</p>
						</div>
						<div>
							<img :src="list.qrcode2" alt="" />
							<p>{{$t('lang.botText2')}}</p>
						</div>
						<div>
							<img :src="list.qrcode3" alt="" />
							<p>{{$t('lang.botText3')}}</p>
						</div>
					</div>
				</div>
				<div class="center-right">
					<div class="list">
						<p @click="delayJump('/introduce')">{{$t('lang.tabAboutUs')}}</p>
						<ul>
							<li @click="delayJump('/introduce')">{{$t('lang.introduce')}}</li>
							<li @click="delayJump('/culture')">{{$t('lang.culture')}}</li>
							<li @click="delayJump('/honor')">{{$t('lang.companyHonor')}}</li>
							<li @click="delayJump('/news')">{{$t('lang.newsCenter')}}</li>
							<li @click="delayJump('/innovate')">{{$t('lang.innovate')}}</li>
							<li @click="delayJump('/develop')">{{$t('lang.develop')}}</li>
						</ul>
					</div>
					<div class="list">
						<p @click="rubberAdditive('/rubber-additive',1)">{{$t('lang.tabRubberAdditive')}}</p>
						<ul>
							<li @click="rubberAdditive('/rubber-additive',1)">{{$t('lang.reba')}}</li>
							<li @click="rubberAdditive('/rubber-additive',2)">{{$t('lang.jinChangSheng')}}</li>
						</ul>
					</div>
					<div class="list">
						<p @click="delayJump('/electronic-chemicals')">{{$t('lang.tabElectronicChemicals')}}</p>
						<ul>
							<li @click="delayJump('/electronic-chemicals')">{{$t('lang.tabElectronicChemicals')}}</li>
							<!-- <li>电子级酚醛环氧树脂</li>
							<li>电子级双马来酰亚胺</li> -->
						</ul>
					</div>
					<div class="list">
						<p @click="delayJump('/recruitment-information')">{{$t('lang.tabRecruitmentInformation')}}</p>
						<ul>
							<li @click="delayJump('/recruitment-information')">{{$t('lang.tabRecruitmentInformation')}}
							</li>
						</ul>
					</div>
					<div class="list">
						<p @click="delayJump('/solution')">{{$t('lang.tabSolution')}}</p>
						<ul>
							<li @click="delayJump('/solution')">{{$t('lang.profession')}}</li>
							<li @click="delayJump('/product')">{{$t('lang.product')}}</li>
						</ul>
					</div>
					<div class="list">
						<p @click="delayJump('/contact-us')">{{$t('lang.tabContactUs')}}</p>
						<ul>
							<li v-for="(item,index) in dataList" :key="index" @click="delayJump('/contact-us')">
								{{item.name}}
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="center-bot">
				<p>
					Copyright @2023 {{$t('lang.botText4')}}
				</p>
				<!-- <p>
					{{$t('lang.botText5')}}：苏ICP备2024059861号
				</p> -->
				<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="text-decoration:none;">
					<p>备案号：苏ICP备2024059861号</p>
				</a>
			</div>
		</div>
		<div class="bot-center" v-if="innerWidth<=1200">
			<div class="logo-img">
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/icon1.png" alt="" />
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/icon2.png" alt="" />
			</div>
			<div class="center-list">
				<div class="item">
					<div class="item-head" @click="tabIndex == 1?tabIndex = 0:tabIndex = 1">
						<span>{{$t('lang.tabAboutUs')}}</span>
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/up-arrow.png" alt=""
							v-if="tabIndex == 1" />
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/down-arrow.png" alt=""
							v-if="tabIndex != 1" />
					</div>
					<div class="child" v-if="tabIndex == 1">
						<div class="tab-item" @click="delayJump('/introduce')">
							{{$t('lang.introduce')}}
						</div>
						<div class="tab-item" @click="delayJump('/culture')">
							{{$t('lang.culture')}}
						</div>
						<div class="tab-item" @click="delayJump('/honor')">
							{{$t('lang.companyHonor')}}
						</div>
						<div class="tab-item" @click="delayJump('/news')">
							{{$t('lang.newsCenter')}}
						</div>
						<div class="tab-item" @click="delayJump('/innovate')">
							{{$t('lang.innovate')}}
						</div>
						<div class="tab-item" @click="delayJump('/develop')">
							{{$t('lang.develop')}}
						</div>
					</div>
				</div>
				<div class="item">
					<div class="item-head" @click="tabIndex == 2?tabIndex = 0:tabIndex = 2">
						<span>{{$t('lang.tabRubberAdditive')}}</span>
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/up-arrow.png" alt=""
							v-if="tabIndex == 2" />
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/down-arrow.png" alt=""
							v-if="tabIndex != 2" />
					</div>
					<div class="child" v-if="tabIndex == 2">
						<div class="tab-item">
							{{$t('lang.reba')}}
						</div>
						<div class="tab-item">
							{{$t('lang.jinChangSheng')}}
						</div>
					</div>
				</div>
				<div class="item">
					<div class="item-head" @click="rubberAdditive('/rubber-additive')">
						<span>{{$t('lang.tabElectronicChemicals')}}</span>
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/down-arrow.png" alt="" />
					</div>
				</div>
				<div class="item">
					<div class="item-head">
						<span>{{$t('lang.tabRecruitmentInformation')}}</span>
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/down-arrow.png" alt="" />
					</div>
				</div>
				<div class="item">
					<div class="item-head" @click="tabIndex == 5?tabIndex = 0:tabIndex = 5">
						<span>{{$t('lang.tabSolution')}}</span>
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/up-arrow.png" alt=""
							v-if="tabIndex == 5" />
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/down-arrow.png" alt=""
							v-if="tabIndex != 5" />
					</div>
					<div class="child" v-if="tabIndex == 5">
						<div class="tab-item" @click="delayJump('/solution')">
							{{$t('lang.profession')}}
						</div>
						<div class="tab-item" @click="delayJump('/product')">
							{{$t('lang.product')}}
						</div>
					</div>
				</div>
				<div class="item">
					<div class="item-head">
						<span>{{$t('lang.tabContactUs')}}</span>
						<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/down-arrow.png" alt="" />
					</div>
				</div>
			</div>
			<div class="follow">
				<div class="title">
					{{$t('lang.botText')}}
				</div>
				<div class="icon-list">
					<div class="item">
						<img :src="list.qrcode1" alt="" />
						<div class="name">{{$t('lang.botText1')}}</div>
					</div>
					<div class="item">
						<img :src="list.qrcode2" alt="" />
						<div class="name">{{$t('lang.botText2')}}</div>
					</div>
					<div class="item">
						<img :src="list.qrcode3" alt="" />
						<div class="name">{{$t('lang.botText3')}}</div>
					</div>
				</div>
			</div>
			<div class="copyright">
				Copyright @2023 {{$t('lang.botText4')}}
			</div>
			<div class="record-number">
				<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="text-decoration:none;">
					{{$t('lang.botText5')}}：苏ICP备2024059861号
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				innerWidth: 0,
				tabIndex: 0,
				list: {
					qrcode1: '',
					qrcode2: '',
					qrcode3: '',
				},
				dataList: [],
			}
		},
		watch: {
			'$i18n.locale'(val) {
				this.getData();
				this.getList();
			}
		},
		mounted() {
			let that = this;
			that.innerWidth = window.innerWidth;
			window.onresize = () => {
				return (() => {
					that.innerWidth = window.innerWidth;
					if (that.innerWidth > 1200) {
						import("@/styles/bottom-information-pc.scss");
					} else {
						import("@/styles/bottom-information-h5.scss");
					}
				})()
			}
			that.getData();
			that.getList();

			if (that.innerWidth > 1200) {
				import("@/styles/bottom-information-pc.scss");
			} else {
				import("@/styles/bottom-information-h5.scss");
			}
		},
		methods: {
			getList() {
				this.http.post('/index.php/api/index/contact').then(res => {
					if (res.code == 1) {
						this.dataList = res.data.data;
					}
				})
			},
			delayJump(url) {
				this.$router.push(url);
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				}); // 平滑滚动到顶部
			},
			rubberAdditive(url, e) {
				this.$router.push({
					path: url,
					query: {
						type: e
					}
				});
			},
			getData() {
				this.http.post('/index.php/api/index/qrinfo').then(res => {
					if (res.code == 1) {
						this.list.qrcode1 = this.getImgUrl + res.data.qrcode1;
						this.list.qrcode2 = this.getImgUrl + res.data.qrcode2;
						this.list.qrcode3 = this.getImgUrl + res.data.qrcode3;
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	// @import url("../styles/bottom-information-pc.scss") screen and (min-width: 1200px);
	// @import url("../styles/bottom-information-h5.scss") screen and (max-width: 1200px);
</style>