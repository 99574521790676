<template>
	<div class="nav-bar-content">
		<div :class="tabShow?'navbar pot-fixed':'navbar'" v-if="innerWidth > 1200">
			<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/logo.png" alt="" class="logo-img"
				@click="delayJump('/')" />
			<div class="navbar-center animate__animated animate__fadeInDown" v-if="!searchShow">
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabHome')" placement="left-start">
					<div class="text" @click="delayJump('/')">
						{{$t('lang.tabHome')}}
						<div class="cross" v-if="jumpUrl!='/'"></div>
						<div class="current" v-if="jumpUrl=='/'"></div>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabAboutUs')" placement="left-start">
					<div class="text about-us" @click="delayJump('/introduce')">
						<el-popover placement="bottom" trigger="hover" :visible-arrow="false">
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/introduce',1)">
								{{$t('lang.introduce')}}
							</div>
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/culture',1)">
								{{$t('lang.culture')}}
							</div>
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/honor',1)">
								{{$t('lang.companyHonor')}}
							</div>
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/news',1)">
								{{$t('lang.newsCenter')}}
							</div>
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/innovate',1)">
								{{$t('lang.innovate')}}
							</div>
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/develop',1)">
								{{$t('lang.develop')}}
							</div>
							<div slot="reference">{{$t('lang.tabAboutUs')}}</div>
						</el-popover>
						<div class="cross" v-if="jumpUrl!='/about-us'"></div>
						<div class="current" v-if="jumpUrl=='/about-us'"></div>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabRubberAdditive')" placement="left-start">
					<div class="text rubber-additive" @click="delayJump('/rubber-additive')">
						<el-popover placement="bottom" trigger="hover" :visible-arrow="false">
							<div class="tab-item" style="cursor: pointer;"
								@click="rubberAdditive('/rubber-additive',1)">
								{{$t('lang.reba')}}
							</div>
							<div class="tab-item" style="cursor: pointer;"
								@click="rubberAdditive('/rubber-additive',2)">
								{{$t('lang.jinChangSheng')}}
							</div>
							<div slot="reference">{{$t('lang.tabRubberAdditive')}}</div>
						</el-popover>
						<div class="cross" v-if="jumpUrl!='/rubber-additive'"></div>
						<div class="current" v-if="jumpUrl=='/rubber-additive'"></div>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabElectronicChemicals')"
					placement="left-start">
					<div class="text" @click="delayJump('/electronic-chemicals')">
						{{$t('lang.tabElectronicChemicals')}}
						<div class="cross" v-if="jumpUrl!='/electronic-chemicals'"></div>
						<div class="current" v-if="jumpUrl=='/electronic-chemicals'"></div>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabSolution')" placement="left-start">
					<div class="text solutions" @click="delayJump('/solution')">
						<el-popover placement="bottom" trigger="hover" :visible-arrow="false">
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/solution')">
								{{$t('lang.profession')}}
							</div>
							<div class="tab-item" style="cursor: pointer;" @click="delayJump('/product',2)">
								{{$t('lang.product')}}
							</div>
							<div slot="reference">{{$t('lang.tabSolution')}}</div>
						</el-popover>
						<div class="cross" v-if="jumpUrl!='/solution'"></div>
						<div class="current" v-if="jumpUrl=='/solution'"></div>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabRecruitmentInformation')"
					placement="left-start">
					<div class="text" @click="delayJump('/recruitment-information')">
						{{$t('lang.tabRecruitmentInformation')}}
						<div class="cross" v-if="jumpUrl!='/recruitment-information'"></div>
						<div class="current" v-if="jumpUrl=='/recruitment-information'"></div>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="$t('lang.tabContactUs')" placement="left-start">
					<div class="text" @click="delayJump('/contact-us')">
						{{$t('lang.tabContactUs')}}
						<div class="cross" v-if="jumpUrl!='/contact-us'"></div>
						<div class="current" v-if="jumpUrl=='/contact-us'"></div>
					</div>
				</el-tooltip>
			</div>
			<div class="search" v-if="!searchShow" @click="setSearch">
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/search.png" alt="" />
			</div>
			<div class="searchInp" v-if="searchShow" id="searchInp">
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/inp-search.png" alt=""
					class="inp-search" />
				<el-input v-model="kword" :placeholder="$t('lang.navBarInp')"></el-input>
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/close.png" alt="" class="close-img"
					@click="closeSearch" />
				<div class="search-list" v-if="searchList.length">
					<div class="item" v-for="(item,index) in searchList" :key="index" @click="goDetails(item)">
						{{item.name}}
					</div>
				</div>
			</div>
			<div class="lang" @click="editLang">
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/lang.png" alt="" />
				<p>{{langTitle}}</p>
			</div>
		</div>
		<div :class="tabShow?'center pot-fixed':'center'" v-if="innerWidth <= 1200">
			<div class="center-head" v-if="!searchShow">
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/logo.png" alt="" class="logo-img"
					@click="delayJump('/')" />
				<div class="select">
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/lang.png" alt="" class="lang-icon"
						@click="editLang" />
					<div class="lang-title" @click="editLang">
						{{langTitle}}
					</div>
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/search.png" alt=""
						class="search-img" @click="searchShow = true" />
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home-h5/select.png" alt=""
						class="select-img" id="select-img" @click="setHead()" />
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/close.png" alt=""
						class="select-img1" id="select-img1" @click="setHead()" />
				</div>
			</div>
			<div class="search" v-if="searchShow">
				<div class="search-inp">
					<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/home/search.png" alt=""
						class="search-img" />
					<el-input v-model="kword" :placeholder="$t('lang.navBarInp')"></el-input>
				</div>
				<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/tab-h5/close.png" alt="" class="close-img"
					@click="closeSearch" />
				<div class="search-list" v-if="searchList.length">
					<div class="item" v-for="(item,index) in searchList" :key="index" @click="goDetails(item)">
						{{item.name}}
					</div>
				</div>
			</div>
			<div class="center-list" id="center-list"> <!-- v-if="headShow" -->
				<div class="center-list-center" id="center-list-center">
					<div class="item">
						<div class="item-head" @click="tabIndex == 1?tabIndex = 0:tabIndex = 1">
							<span>{{$t('lang.tabAboutUs')}}</span>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/up-arrow.png" alt=""
								v-if="tabIndex == 1" />
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png" alt=""
								v-if="tabIndex != 1" />
						</div>
						<div class="child" v-if="tabIndex == 1">
							<div class="tab-item" @click="delayJump('/introduce')">
								{{$t('lang.introduce')}}
							</div>
							<div class="tab-item" @click="delayJump('/culture')">
								{{$t('lang.culture')}}
							</div>
							<div class="tab-item" @click="delayJump('/honor')">
								{{$t('lang.companyHonor')}}
							</div>
							<div class="tab-item" @click="delayJump('/news')">
								{{$t('lang.newsCenter')}}
							</div>
							<div class="tab-item" @click="delayJump('/innovate')">
								{{$t('lang.innovate')}}
							</div>
							<div class="tab-item" @click="delayJump('/develop')">
								{{$t('lang.develop')}}
							</div>
						</div>
					</div>
					<div class="item">
						<div class="item-head" @click="tabIndex == 2?tabIndex = 0:tabIndex = 2">
							<span>{{$t('lang.tabRubberAdditive')}}</span>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/up-arrow.png" alt=""
								v-if="tabIndex == 2" />
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png" alt=""
								v-if="tabIndex != 2" />
						</div>
						<div class="child" v-if="tabIndex == 2">
							<div class="tab-item" @click="rubberAdditive('/rubber-additive',1)">
								{{$t('lang.reba')}}
							</div>
							<div class="tab-item" @click="rubberAdditive('/rubber-additive',2)">
								{{$t('lang.jinChangSheng')}}
							</div>
						</div>
					</div>
					<div class="item" @click="rubberAdditive('/rubber-additive',3)">
						<div class="item-head">
							<span>{{$t('lang.tabElectronicChemicals')}}</span>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png" alt="" />
						</div>
					</div>
					<div class="item">
						<div class="item-head" @click="tabIndex == 5?tabIndex = 0:tabIndex = 5">
							<span>{{$t('lang.tabSolution')}}</span>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/up-arrow.png" alt=""
								v-if="tabIndex == 5" />
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png" alt=""
								v-if="tabIndex != 5" />
						</div>
						<div class="child" v-if="tabIndex == 5">
							<div class="tab-item" @click="delayJump('/solution')">
								{{$t('lang.profession')}}
							</div>
							<div class="tab-item" @click="delayJump('/product')">
								{{$t('lang.product')}}
							</div>
						</div>
					</div>
					<div class="item" @click="delayJump('/recruitment-information')">
						<div class="item-head">
							<span>{{$t('lang.tabRecruitmentInformation')}}</span>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png" alt="" />
						</div>
					</div>
					<div class="item" @click="delayJump('/contact-us')">
						<div class="item-head">
							<span>{{$t('lang.tabContactUs')}}</span>
							<img src="https://ruiba.oss-cn-beijing.aliyuncs.com/assets/tab-h5/down-arrow.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		data() {
			return {
				jumpUrl: '/',
				langTitle: 'CN',
				searchShow: false,
				headShow: false,
				innerWidth: '',
				kword: '',
				tabIndex: 0,
				tabShow: false,
				searchList: [],
			}
		},
		watch: {
			$route(val) {
				console.log(val);
				this.$cookies.set('jumpUrl', val.matched[0].path || '/');
				this.jumpUrl = val.matched[0].path || '/';
			},
			kword(val) {
				this.kword = val;
				this.getSearchList();
			}
		},
		mounted() {
			let that = this;
			that.innerWidth = window.innerWidth;
			window.onresize = () => {
				return (() => {
					that.innerWidth = window.innerWidth;
					if (that.innerWidth > 1200) {
						import("@/styles/nav-bar-pc.scss");
					} else {
						import("@/styles/nav-bar-h5.scss");
					}
				})()
			}
			that.jumpUrl = that.$cookies.get('jumpUrl') || '/';
			new WOW().init()
			window.addEventListener("scroll", that.handleScroll);

			if (that.innerWidth > 1200) {
				import("@/styles/nav-bar-pc.scss");
			} else {
				import("@/styles/nav-bar-h5.scss");
			}

			let language = that.$cookies.get('language');
			if (!language || language == 0) {
				that.$i18n.locale = 'zh-CN';
				that.langTitle = 'CN';
			} else {
				that.$i18n.locale = 'en-US';
				that.langTitle = 'EN';
			}
		},
		methods: {
			setHead() {
				this.headShow = !this.headShow;
				if (this.headShow) {
					let center_list = document.getElementById("center-list");
					let center_list_center = document.getElementById("center-list-center");
					let select_img = document.getElementById("select-img");
					let select_img1 = document.getElementById("select-img1");
					center_list.style.height = '100vh';
					center_list.style.opacity = 1;
					select_img.style.opacity = 0;
					select_img1.style.opacity = 1;
					center_list_center.style.display = 'block';
				} else {
					let center_list = document.getElementById("center-list");
					let center_list_center = document.getElementById("center-list-center");
					let select_img = document.getElementById("select-img");
					let select_img1 = document.getElementById("select-img1");
					center_list.style.height = '0vh';
					center_list.style.opacity = 0;
					select_img.style.opacity = 1;
					select_img1.style.opacity = 0;
					center_list_center.style.display = 'none';
				}
			},
			goDetails(item) {
				this.$router.push({
					path: '/news-details',
					query: {
						id: item.id,
						type: 2,
					}
				});
			},
			getSearchList() {
				this.http.post('/index.php/api/index/profaS', {
					kword: this.kword,
				}).then(res => {
					if (res.code == 1) {
						this.searchList = res.data;
					}
				})
			},
			setSearch() {
				this.searchShow = true;
				// let searchInp = document.getElementById("searchInp");
				// searchInp.style.display = 'block';
				// searchInp.style.width = '900px';
			},
			closeSearch() {
				this.kword = '';
				this.searchList = [];
				this.searchShow = false;
				// let searchInp = document.getElementById("searchInp");
				// searchInp.style.display = 'none';
				// searchInp.style.width = '0px';
			},
			handleScroll() {
				// 页面滚动距顶部距离
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 200) {
					this.tabShow = true;
				} else {
					this.tabShow = false;
				}
			},
			delayJump(url, e) {
				this.headShow = false;
				this.$router.push(url);
				if (this.innerWidth <= 1200) {
					let center_list = document.getElementById("center-list");
					let select_img = document.getElementById("select-img");
					let select_img1 = document.getElementById("select-img1");
					let center_list_center = document.getElementById("center-list-center");
					select_img.style.opacity = 1;
					select_img1.style.opacity = 0;
					center_list.style.height = '0vh';
					center_list.style.opacity = 0;
					center_list_center.style.display = 'none';
				}
			},
			rubberAdditive(url, e) {
				this.headShow = false;
				this.$router.push({
					path: url,
					query: {
						type: e
					}
				});
				if (this.innerWidth <= 1200) {
					let center_list = document.getElementById("center-list");
					let select_img = document.getElementById("select-img");
					let select_img1 = document.getElementById("select-img1");
					let center_list_center = document.getElementById("center-list-center");
					select_img.style.opacity = 1;
					select_img1.style.opacity = 0;
					center_list.style.height = '0vh';
					center_list.style.opacity = 0;
					center_list_center.style.display = 'none';
				}
			},
			editLang() {
				if (this.langTitle == 'CN') {
					this.$i18n.locale = 'en-US';
					this.langTitle = 'EN';
					this.$cookies.set('language', 1);
				} else {
					this.$i18n.locale = 'zh-CN';
					this.langTitle = 'CN';
					this.$cookies.set('language', 0);
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	// @import url("../styles/nav-bar-pc.scss") screen and (min-width: 1200px);
	// @import url("../styles/nav-bar-h5.scss") screen and (max-width: 1200px);
</style>