export const lang = {
	//home
	tabHome:'Home',
	tabAboutUs:'About Us',
	tabRubberAdditive:'Rubber additives',
	tabElectronicChemicals:'Electronic chemicals',
	tabRecruitmentInformation:'Invite',
	tabSolution:'Solution',
	tabSolutions:'Multiple fields, wider coverage, help industries better development',
	tabContactUs:'Contact Us',
	introduce:'Introduce',
	culture:'Culture',
	companyHonor:'Honor',
	newsCenter:'News',
	innovate:'Innovate',
	develop:'sustainable development',
	reba:'Rebo Product',
	jinChangSheng:'Longsunchem',
	profession:'Profession',
	product:'Product',
	rebaNew:'Rebo New Material',
	understand:'Learn more about',
	intro:'Rebo New Material Group, formerly known as Longsunchem Group, was founded in 2000. It is a professional, innovative enterprise dedicated to the research and development, production, sales and technical services of rubber and plastic functional materials and electronic chemical new materials.',
	intro1:"At present, the company's products mainly serve the rubber field such as tires, rubber and plastic products, shoes and copper clad plate, chip plastic sealing material (EMC), solder resistance ink, photoresist and other electronic chemicals.",
	tireCustomer:'Tire customer',
	rubberPlastic:'Rubber and plastic',
	shoeMaterial:'Shoe material',
	establishmentTime:'Establishment time',
	seeMore:'See more',
	furtherUnderstanding:'Further understanding',
	viewNow:'View now',
	newsTrends:'News trends',
	//about-us --culture
	culture1:'Rubber additives and electronic chemical solutions for global customers',
	culture2:'Excellent quality',
	culture3:'Serve with heart',
	culture4:'Corporate values',
	culture5:'Integrity, innovation, quality, talent, win-win',
	culture6:'Enterprise mission',
	culture7:'To provide high quality rubber raw materials and services for the rubber industry',
	culture8:'Make rubber better benefit mankind',
	culture9:'Core of corporate culture',
	culture10:'Continuous improvement',
	culture11:'Customer first service',
	culture12:'Happy work wonderful life',
	culture13:'Corporate culture',
	//about-us --develop
	develop1:'PCR tire life cycle carbon emissions',
	//about-us --honor
	honor1:'Honor and qualification',
	//about-us --innovate
	innovate1:'Jinchangsheng rubber master lecture Hall',
	//about-us --introduce
	introduce1:'Main product',
	//contact-us
	mailbox:'E-mail',
	postcode:'Postcode',
	address:'Address',
	headOffice:'Head office',
	//news-details
	newsDetails1:'News details',
	newsDetails2:'Scheme details',
	newsDetails3:'Publisher',
	newsDetails4:'Release time',
	newsDetails5:'Previous post',
	newsDetails6:'Next chapter',
	newsDetails7:'Relevant recommendation',
	//solution
	solution1:'Related products',
	solution2:'Multiple fields, wider coverage, help industries better development',
	//navBar
	navBarInp:'Please enter what you are searching for',
	//botText
	botText:'Follow us',
	botText1:'Jin Changsheng',
	botText2:'Reba Mall',
	botText3:'Public account',
	botText4:'Jiangsu Reba New Material Technology Co., LTD. All rights reserved',
	botText5:'Record number',
	tel:'TEL',
	fax:'FAX',
}