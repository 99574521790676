import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem.js'
import "animate.css"
import Cookies from "js-cookie";
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
import http from './utils/http.js'
import imgUrl from './utils/imgUrl.js'

import './assets/font/font.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入中英文切换插件vue-i18n
import VueI18n from 'vue-i18n'
import VueLazyload from 'vue-lazyload';


Vue.prototype.$cookies = Cookies;
Vue.prototype.http = http;
Vue.prototype.getImgUrl = imgUrl;


Vue.use(ElementUI);
Vue.use(VueI18n); // 挂载
Vue.use(VueLazyload);

Vue.config.productionTip = false;

const i18n = new VueI18n({
	locale: 'zh-CN', // 语言标识
	messages: {
		'zh-CN': require('./language/cn'), // 通过require引入中文语言包
		'en-US': require('./language/en') // 通过require引入英文语言包
	}
})

// 滚动动画 wow.js
import {
	WOW
} from 'wowjs'
Vue.prototype.$wow = new WOW({
	boxClass: 'wow', // default
	animateClass: 'animated', // default
	offset: 10, // default
	mobile: true, // default
	live: true,
});

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')