export const lang = {
	//home
	tabHome:'首页',
	tabAboutUs:'关于我们',
	tabRubberAdditive:'橡胶助剂',
	tabElectronicChemicals:'电子化学品',
	tabRecruitmentInformation:'招聘信息',
	tabSolution:'解决方案',
	tabSolutions:'多领域，覆盖面更广，助力各行业更好发展',
	tabContactUs:'联系我们',
	introduce:'公司介绍',
	culture:'企业文化',
	companyHonor:'公司荣誉',
	newsCenter:'新闻中心',
	innovate:'科研创新',
	develop:'可持续发展',
	reba:'锐巴产品',
	jinChangSheng:'金昌盛产品',
	profession:'行业解决方案',
	product:'产品解决方案',
	rebaNew:'锐巴新材',
	understand:'进一步了解',
	intro:'锐巴新材前身金昌盛集团创立于2000年，是一家专注于橡塑功能材料和电子化学品新材料研发、生产、销售、服务的专业型技术创新公司。',
	intro1:'目前公司产品主要服务于轮胎、橡塑制品、鞋材等橡胶领域和覆铜板、芯片塑封料（EMC）、阻焊油墨、光刻胶等电子化学品领域。',
	tireCustomer:'轮胎客户',
	rubberPlastic:'橡塑制品客户',
	shoeMaterial:'鞋材客户',
	establishmentTime:'成立时间',
	seeMore:'查看更多',
	furtherUnderstanding:'进一步了解',
	viewNow:'立即查看',
	newsTrends:'新闻动态',
	//about-us --culture
	culture1:'为全球客户提供橡胶助剂和电子化学品解决方案',
	culture2:'卓越品质',
	culture3:'用心服务',
	culture4:'企业价值观',
	culture5:'诚信、创新、品质、人才、共赢',
	culture6:'企业使命',
	culture7:'为橡胶行业提供优质的橡胶原料和服务',
	culture8:'使橡胶更好地造福人类',
	culture9:'企业文化核心',
	culture10:'持续改善 精益求精',
	culture11:'客户至上 用心服务',
	culture12:'快乐工作 精彩生活',
	culture13:'企业文化',
	//about-us --develop
	develop1:'PCR轮胎生命周期碳排放',
	//about-us --honor
	honor1:'荣誉资质',
	//about-us --innovate
	innovate1:'金昌盛橡胶名师大讲堂',
	//about-us --introduce
	introduce1:'主要产品',
	//contact-us
	mailbox:'邮箱',
	postcode:'邮编',
	address:'地址',
	headOffice:'总部地址',
	//news-details
	newsDetails1:'新闻详情',
	newsDetails2:'方案详情',
	newsDetails3:'发布人',
	newsDetails4:'发布时间',
	newsDetails5:'上一篇',
	newsDetails6:'下一篇',
	newsDetails7:'相关推荐',
	//solution
	solution1:'相关产品',
	solution2:'多领域，覆盖面更广，助力各行业更好发展',
	//navBar
	navBarInp:'请输入您要搜索的内容',
	//botText
	botText:'关注我们',
	botText1:'金昌盛公众号',
	botText2:'锐巴商城',
	botText3:'锐巴公众号',
	botText4:'江苏锐巴新材料科技有限公司 版权所有',
	botText5:'备案号',
	tel:'电话',
	fax:'传真',
}